/// <reference types="google.accounts, jsonwebtoken" />

<script setup lang="ts">
import { onMounted, provide, watch } from 'vue';
import { useUserStore } from '../store/user';
import { mdiLink } from '@mdi/js';
import { AppConfig}  from '../config';
import { storeToRefs } from 'pinia';

var windowObjectReference: any = null;
let previousURL: string;
const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

const userStore = useUserStore();
const { needsYoutubeLogin, needsGoogleLogin } = storeToRefs(userStore);

watch(needsGoogleLogin, handleNeedsGoogleLogin);

function handleNeedsGoogleLogin(needsLogin: any, _: any) {
    if (needsLogin) {
        showGoogleLogin();
    }
}

function showGoogleLogin() {
    google.accounts.id.initialize({
        client_id: "15103251220-k3e1a9tm54ogrj5ou4n7d7640tbqagl6.apps.googleusercontent.com",
        auto_select: true,
        callback: handleCredentialResponse
    });
    google.accounts.id.renderButton(
        document.getElementById("googleLogin")!,
        { theme: "outline", size: "large", shape: "pill" }  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
}

async function handleCredentialResponse(response: google.CredentialResponse) {
    await userStore.getGoogleUser(response.credential);
    userStore.login(response.credential).then(() => {
        provide('user', userStore.user);
    })
}

function onConnectYoutube() {
    needsYoutubeLogin.value = false;
    function openRequestedSingleTab(url: string) {
        if (windowObjectReference === null || windowObjectReference.closed) {
            windowObjectReference = window.open(url, "ConnectYoutube", strWindowFeatures);
        } else if (previousURL !== url) {
            windowObjectReference = window.open(url, "ConnectYoutube", strWindowFeatures);
            windowObjectReference.focus();
        } else {
            windowObjectReference.focus();
        }
        windowObjectReference.addEventListener('message', (e:MessageEvent) => receiveMessage(e), false);
        windowObjectReference.addEventListener('beforeunload', (e:Event) => receiveMessage(e), false);
        var timer = setInterval(function() { 
            if(windowObjectReference.closed) {
                clearInterval(timer);
                location.reload();
            }
        }, 1000);
        previousURL = url;
    }

    function receiveMessage(e: Event) {
        e.preventDefault();
        // get user data by updating the user.
        location.reload();
        return false;
    }

    openRequestedSingleTab(AppConfig.DOMAIN + 'youtube-connect');
}

onMounted(() => {
    userStore.getCurrentUser();
});

</script>

<template>

<div id="googleLogin" v-show="needsGoogleLogin"></div>

<v-dialog
    v-model="needsYoutubeLogin"
    width="auto"
>
    <v-card
        max-width="400"
        title="Link to YouTube"
    >
        <!--
            clicjar logo, link icon, youtube logo
            Description of why.
            Will not be able to use clicjar with linking to youtube.
        -->
        <v-card-text>
            <div class="hero-illustration">
                <v-img src="/favicon.svg" height="50"></v-img>
                <v-icon :icon="mdiLink" class="mdi-rotate-45" size="x-large"></v-icon>
                <v-img src="https://developers.google.com/static/site-assets/logo-youtube.svg" height="50"></v-img>
            </div>
            <p class="description">
                Clicjar needs permission to link to your YouTube account to show you links from your subscriptions and know which channels you own. You will not be able to use Clicjar until you link your YouTube account.
            </p>
        </v-card-text>
        <template v-slot:actions>
            <v-btn
                class="ms-auto"
                text="Cancel"
                @click="needsYoutubeLogin = false"
            ></v-btn>
            <v-btn
                class="ms-auto"
                color="primary"
                text="link to YouTube"
                @click="onConnectYoutube"
            ></v-btn>
        </template>
    </v-card>
</v-dialog>
</template>

<style scoped>
.user-avatar {
    max-width: 40px;
    max-height: 40px;
    border-radius: 100%;
}

.sign-out {
    cursor: pointer;
}

.hero-illustration {
    display: flex;
    margin: 50px 0;
    align-items: center;
    flex-direction: row;
}

.description {
    font-size: small;
}
</style>