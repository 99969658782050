import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './routes'

// Vuetify
import 'vuetify/styles'
//import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { createPinia } from 'pinia'
import VueGtag from "vue-gtag";

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  defaults: {
    global: {
      style: 'text-transform: none;',
    }
  }
});

const pinia = createPinia();

const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(pinia);

if (import.meta.env.VITE_APP_GA_TAG) {
  app.use(VueGtag, {
    config: { id: import.meta.env.VITE_APP_GA_TAG },
    enabled: false
  });
}

app.mount('#app');

console.log(import.meta.env.VUE_APP_API_URL);
