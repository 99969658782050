import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from './store/user';
import { hasChannelPermissions } from './store/utils';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('./views/HomePage.vue'),
        },
        {
            path: '/channels/:channelId',
            name: 'channel',
            component: () => import('./views/ChannelPage.vue'),
        },
        /*
        {
            path: '/channels/:channelId/metrics/',
            name: 'metrics',
            component: ChannelMetricsPage,
            meta: {isAdmin: true}
        },
        */
        {
            path: '/channels/:channelId/lists/:listId',
            name: 'list',
            component: () => import('./views/ListPage.vue'),
        },
        {
            path: '/channels/:channelId/links',
            name: 'links',
            component: () => import('./views/ChannelLinksPage.vue'),
        },
        {
            path: '/channels/:channelId/links/:linkId',
            name: 'link',
            component: () => import('./views/LinkPage.vue'),
            meta: {isAdmin: true}
        },
        {
            path: '/channels/:channelId/videos/:videoId',
            name: 'video',
            component: () => import('./views/VideoPage.vue'),
        },
        {
            path: '/channels/:channelId/settings',
            name: 'channel-settings',
            component: () => import('./views/ChannelSettingsPage.vue'),
            meta: {isAdmin: true}
        },
    ]
});

router.beforeEach((to, _) => {
    if (to.meta.isAdmin) {
        const userStore = useUserStore();
        const channelId = to.params.channelId as string;
        let hasPermissions = hasChannelPermissions(userStore.user, channelId);
        
        if (hasPermissions) {
            return true;
        } else {
            return {name: 'home'};
        }
    }
});

export default router;