/// <reference types="google.accounts, jsonwebtoken" />
<script setup lang="ts">
import { toRefs, watch, onMounted } from 'vue';
import { User } from '../models/link';
import { useUserStore } from '../store/user';
import { useChannelStore } from '../store/channels';
import FreeClickProgress from './FreeClickProgress.vue';

const props = defineProps<{user: User, showDrawer: boolean}>();
const { user, showDrawer } = toRefs(props);

const userStore = useUserStore();
const channelStore = useChannelStore();

watch(showDrawer, () => {
    if (showDrawer.value) {
        getClickCounts();
    }
},
{ immediate: true });

onMounted(getClickCounts);

function getClickCounts() {
    console.log('Get click counts');
    if (user.value.ownedChannels) {
        console.log('User has channels. Getting click counts');
        user.value.ownedChannels.forEach(async element => {
            element.clickCount = await channelStore.getChannelClickCount(element.id);
        });
    }
}

function signout() {
    userStore.logout();
}
</script>

<template>

<v-navigation-drawer
    v-model="showDrawer"
    location="left"
    temporary
    class="drawer"
    >
    <v-list-item
        :prepend-avatar="user.imageUrl"
        :title="user.name"
    >
        <template v-slot:subtitle>
            <a class="sign-out" @click.stop.prevent="signout">Sign out</a>
        </template>
    </v-list-item>

    <v-list v-if="user.ownedChannels">
        <template v-for="(item, _) in user.ownedChannels">
            <v-divider class="owned-channel-divider"></v-divider>
            <v-list-subheader><v-avatar :image="item.imageUrl"></v-avatar> {{item.name}}</v-list-subheader>
            <FreeClickProgress :click-count="item.clickCount || 0" :free-click-count="50"/>
            <v-list-item
                :to="`/channels/${item.id}`"
                color="primary"
                variant="plain"
            >Channel page</v-list-item>
            <v-list-item
                :to="`/channels/${item.id}/metrics`"
                color="primary"
                variant="plain"
            >Metrics</v-list-item>
            <v-list-item
                :to="`/channels/${item.id}/settings`"
                color="primary"
                variant="plain"
            >Settings</v-list-item>
        </template>
    </v-list>
</v-navigation-drawer>

</template>
<style scoped>
.sign-out {
    cursor: pointer;
}
.drawer {
    text-align: left;
}
.owned-channel-divider {
    margin-bottom: 20px;
}
</style>