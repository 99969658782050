import { defineStore } from "pinia";
import { ref } from "vue";
import { AppConfig}  from '../config'
import { computed } from "vue";
import { Link, RecentVideo, LinkType } from "../models/link";
import { createLinkUrl, createFetchURL } from "./utils";

export const useLinkStore = defineStore('links', () => {
    const allLinks = ref(<Link[]>[]);
    const channelLinks = ref(<Link[]>[]);
    const recentLinks = ref(<RecentVideo[]>[]);
    const amazonLinks = ref(<Link[]>[]);
    const link = ref(<Link>{});

    const sponsoredLinks = computed(() => {
      return allLinks.value.filter(el => el.type == 'sponsored')
    });

    async function fetchChannelLinks(channelId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}/links`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        const data = await response.json();
        channelLinks.value = _convertResToLinks(data);
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    async function fetchLink(linkId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/links/${linkId}`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        let data = await response.json();
        link.value = _convertLinkToLink(data); //_convertResToLinks(data);
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    async function updateLink(link: Link) {
      try {
        const reqData = JSON.stringify({
          name: link.name,
          imageUrl: link.imageUrl,
          type: link.type,
          discount: link.discount.amount,
          code: link.discount.code});
          
        const response = await fetch(AppConfig.API_DOMAIN + `/links/${link.id}`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: reqData
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        let data = await response.json();
        console.log(data);
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    async function fetchLinks() {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + '/links', {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        let data = await response.json();
        //allLinks.value = _convertResToLinks(data.filter((el:LinkRes) => el.name != ''));
        allLinks.value = _convertResToLinks(data);
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    /**
     * Get the most recent links
     * @param {Object} filters Filters to apply to the results.
     */
    async function fetchRecentLinks(filters: LinkOpts) {
      try {
        let url = createFetchURL(AppConfig.API_DOMAIN + '/links/recent', filters);
        const response = await fetch(url, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        let data = await response.json();
        recentLinks.value = _convertLinksToRecentLinks(data as Link[]);
        recentLinks.value.sort((a, b) => {
          let ap = new Date(a.video.publishedAt || 0);
          let bp = new Date(b.video.publishedAt || 0);
          if (ap > bp) {
            return -1;
          } else if (ap < bp) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    async function fetchAmazonAffiliateLinks(filters: LinkOpts) {
      try {
        let url = createFetchURL(AppConfig.API_DOMAIN + '/links/amazon', filters);
        const response = await fetch(url, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        let data = await response.json();
        data.map((el: Link) => {
          el.imageUrl = `https://www.google.com/s2/favicons?sz=64&domain=${el.domain}`
        });
        amazonLinks.value = data;
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    async function deleteLink(link: Link) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/links/${link.id}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        fetchChannelLinks(link.channel.id);
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    function reset() {
      allLinks.value = [];
      channelLinks.value = [];
      recentLinks.value = [];
      amazonLinks.value = [];
      link.value = {} as Link;
    }

    function _convertResToLinks(res: LinkRes[]) {
      return res.map((el: LinkRes) => ({
        id: el.linkId,
        url: createLinkUrl(AppConfig.DOMAIN, el.url, el.linkId),
        resolvedUrl: el.resolvedUrl,
        type: el.linkType,
        videoCount: 1,
        imageUrl: el.imageUrl || `https://www.google.com/s2/favicons?sz=64&domain=${el.domain}`,
        name: el.name || el.resolvedUrl || el.url,
        domain: el.domain,
        channel: {
            id: el.channelId,
            name: el.channelName,
            imageUrl: el.channelPictureUrl
        },
        discount: {
            amount: el.discount,
            code: el.code
        },
        isSponsored: el.isSponsored
      }));
    }

    function _convertLinksToRecentLinks(res: Link[]) {
      // Create a dictionary of video links where video id is the key and value is an array of links
      let videos: {[s: string]: Link[];} = {};
      let results = [];
      for (let i = 0; i < res.length; i++) {
        let link = res[i];
        if (!link.video) {
          break;
        }

        let video = link!.video;
        if (videos[video.id]) {
          videos[video.id].push(link);
        } else {
          videos[video.id] = [link];
        }
      }

      for (const key in videos) {
        videos[key].forEach((l: Link) => { 
          l.imageUrl = l.imageUrl || `https://www.google.com/s2/favicons?sz=64&domain=${l.domain}`;
          l.name = l.name || l.resolvedUrl || l.url;
          l.url = createLinkUrl(AppConfig.DOMAIN, l.url, l.id);
          l.video!.publishedAt = new Date(l.video?.publishedAt || '').toLocaleDateString();
        });
        let links: Link[] = videos[key];
        if (!links[0].video) {
          break;
        }

        links.sort((a: Link, b: Link) => {
          // if $b link occurs less often OR $b is a sponsored link that it should be ranked higher
          if ((a.videoCount > b.videoCount) || (a.type != LinkType.Sponsored && b.type == LinkType.Sponsored)) {
            return -1;
          }
          if ((a.videoCount < b.videoCount) || (a.type == LinkType.Sponsored && b.type != LinkType.Sponsored)) {
            return 1;
          }
        
          return 0;
        });

        let video = links[0]!.video;
        results.push({
          video: { 
            id: video.id,
            title: video.title,
            thumbnailUrl: video.thumbnailUrl,
            publishedAt: video.publishedAt,
            ytId: video!.yt_video_id
          },
          links: links
        });
      }

      return results
    }

    function _convertLinkToLink(link: any) {
      return {
        id: link.id,
        url: link.url,
        imageUrl: link.imageUrl,
        name: link.name,
        type: link.type,
        videoCount: link.linkVideoCount,
        video: {
          title: link.ytVideoName,
          thumbnailUrl: link.ytVideoThumbUrl
        },
        discount: {
          code: link.code,
          amount: link.discount
        },
        isQuickLink: link.isQuickLink
      } as Link
    }
  
    return {
      link, allLinks, amazonLinks, sponsoredLinks, channelLinks, recentLinks,
      fetchChannelLinks, fetchLinks, fetchRecentLinks, fetchAmazonAffiliateLinks,fetchLink, updateLink, deleteLink, reset 
    }
});

export interface LinkRes {
  channelId: string,
  channelName: string,
  channelPictureUrl: string
  youtubeId: string,
  quickLinks: Link[]
  linkId: string
  url: string
  resolvedUrl?: string
  linkType: string
  name: string
  domain: string
  discount: string
  code: string
  imageUrl: string
  isSponsored: Boolean
}

export interface LinkOpts {
  userId?: string
  channelId?: string
  publishedAfter?: string
  limit?: Number 
}