<script setup lang="ts">
import { toRefs, computed } from 'vue'
//import { mdiPlus } from '@mdi/js';

const props = defineProps({clickCount:{type: Number, required: true}, freeClickCount: {type: Number, required: true}});
const { clickCount, freeClickCount } = toRefs(props);

const progress = computed(() => {
    if (clickCount.value && freeClickCount.value) {
        return clickCount.value / freeClickCount.value * 100;
    } else {
        return 0;
    }
});

</script>

<template>
    <div class="wrapper">
        <div class="values">{{clickCount}} / {{freeClickCount}}</div>
        <div class="progress-bg">
            <div class="progress" :style="{ width: progress + '%'}"></div>
        </div>
    </div>
</template>

<style scoped>
.wrapper {
    margin: 16px 20px;
}
.progress-bg {
    height: 10px;
    background-color: #eee;
    border-radius: 25px;
}
.progress {
    height: 100%;
    background-color: green;
    border-radius: 25px;
}
.values {
    font-size: x-small;
    text-align: left;
    color: #666;
}
</style>