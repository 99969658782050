<script setup lang="ts">
import { CookieConsent } from "../../models/link";
import { ref, onMounted } from 'vue';
import { useUserStore } from '../../store/user';

const showDialog = ref(false);
const userStore = useUserStore();

onMounted(() => {
    const userConsent = userStore.getCookieConsent();

    if (userConsent === CookieConsent.NotSet) {
        showDialog.value = true;
    } else if (userConsent === CookieConsent.AcceptAll) {
        //this.$gtag.enabled = true;
    }
});

function onAcceptAllClick() {
    console.log('accept all cookies');
    userStore.saveCookieConsent(CookieConsent.AcceptAll);
    showDialog.value = false;
}

function onOnlyNecessaryClick() {
    console.log('only necessary cookies');
    userStore.saveCookieConsent(CookieConsent.OnlyNecessary);
    showDialog.value = false;
}

function onRejectAllClick() {
    console.log('accept all cookies');
    userStore.saveCookieConsent(CookieConsent.RejectAll);
    showDialog.value = false;
}
</script>

<template>
<v-bottom-sheet v-model="showDialog">
    <v-card
        title="Cookie consent"
        text="We use necessary cookies to make our site work. We'd like to set additional cookies to understand site usage, make site improvements, and remember your settings."
    >
    <v-card-actions>
        <v-btn @click="onRejectAllClick">Reject all</v-btn>
        <v-btn @click="onOnlyNecessaryClick">Only necessary</v-btn>
        <v-btn @click="onAcceptAllClick">Accept all</v-btn>
    </v-card-actions>
    </v-card>
</v-bottom-sheet>
</template>

<style scoped>

</style>