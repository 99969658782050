//// <reference types="google.accounts, jsonwebtoken" />

import { defineStore } from "pinia";
import { ref } from "vue";
import { User, CookieConsent } from "../models/link";
import { AppConfig}  from '../config';
import { useLinkStore } from './links';
import { useListsStore } from './lists';
import { useChannelStore } from './channels';
import Cookies from "js-cookie";

export const useUserStore = defineStore('user', () => {
  let user = ref({} as User);
  let needsSiteLogin = ref(false);
  let needsYoutubeLogin = ref(false);
  let needsGoogleLogin = ref(false);

  async function getGoogleUser(token: string) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`);
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    const jsonData = await response.json();
    user.value = {
      id: '' || user.value.id,
      imageUrl: jsonData.picture,
      name: jsonData.name,
      token: jsonData,
      tokenId: token
    };
    _saveUserInfoToCookie(jsonData);
    needsGoogleLogin.value = false;
  }

  function _saveUserInfoToCookie(user:any) {
    Cookies.set('user', JSON.stringify({name: user.name, imageUrl: user.picture, tokenId: user.tokenId}));
  }

  function _getUserInfoFromCookie() {
    let userCookie = Cookies.get('user');
    if (!userCookie) {
      return {};
    }
    return JSON.parse(userCookie);
  }

  function saveCookieConsent(consentStatus: CookieConsent) {
    Cookies.set('consent', consentStatus);
  }

  function getCookieConsent() {
    let cookieConsent = Cookies.get('consent');
    if (!cookieConsent) {
      return CookieConsent.NotSet;
    }
    return cookieConsent;
  }

  async function login(tokenID: string) {
    const reqData = JSON.stringify({id: tokenID});

    const response = await fetch(AppConfig.DOMAIN + `login`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: reqData
    });
    if (!response.ok) {
      if (response.status == 401) {
        needsSiteLogin.value = true;
      } else {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return
    } else if (response.status == 206) {
      needsYoutubeLogin.value = true; 
    }
    const jsonData = await response.json();
    
    user.value.id = jsonData.id;
    user.value.isAdmin = jsonData.role == 'admin'
    user.value.ownedChannels = jsonData.ownedChannels;
    needsSiteLogin.value = false;
  }

  async function getCurrentUser() {
    const response = await fetch(AppConfig.API_DOMAIN + `/user`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    });
    if (!response.ok) {
      if (response.status == 401) {
        // if we have a token from google then login to clicjar with it.
        if (user.value.tokenId && user.value.tokenId.length > 0) {
          login(user.value.tokenId);
        } else {
          needsGoogleLogin.value = true;
        }
      } else {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return
    }
    const jsonData = await response.json();
    const userCookie = _getUserInfoFromCookie();

    if (userCookie.imageUrl && userCookie.name) {
      needsGoogleLogin.value = false;
    }
    
    user.value.id = jsonData.id || user.value.id;
    user.value.isAdmin = jsonData.role == 'admin'
    user.value.ownedChannels = jsonData.ownedChannels;
    user.value.imageUrl = userCookie.imageUrl;
    user.value.name = userCookie.name;
  }

  async function logout() {
    if (!user.value.id) return;

    // call logout to remove session data and cookie.
    const response = await fetch(AppConfig.DOMAIN + `logout`, {
      method: 'POST',
      credentials: 'include'
    });
    if (!response.ok) {
      if (response.status == 401) {
        needsSiteLogin.value = true;
        throw new Error('401');
      } else {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
    }
    google.accounts.id.revoke(user.value.token!.sub!, done => {
      console.log(done.error);
    });
    google.accounts.id.disableAutoSelect();
    // remove data from other stores.
    _reset();
  }

  function _reset() {
    user.value.id = '';
    needsSiteLogin.value = true;
    needsGoogleLogin.value = true;
    user.value.isAdmin = false;
    useLinkStore().reset();
    useListsStore().reset();
    useChannelStore().reset();
  }
  
  return { 
    user,
    needsSiteLogin, needsYoutubeLogin, needsGoogleLogin,
    getGoogleUser, getCurrentUser, login, logout, getCookieConsent, saveCookieConsent }
});